<header class="content-page__header section-inset-t">
  <section class="bg-primary" [class.section-inset-y]="!page.image?.url">
    <div class="container">
      <div class="row justify-content-center">
        <div class="container-size-xs">
          <a class="button button-link button--light button--xs mb-3" (click)="back()">
            <fstr-icon anchor="icon-arrow-left"></fstr-icon>
            <span>Terug</span>
          </a>

          <h1 class="text-white">
            {{ page.title }}
          </h1>
          <div class="content-page-header__meta mb-4" *ngIf="page.tags || page.publishDate">
            <span>
              <time *ngIf="page.publishDate && !page.hideDate" class="text-muted text-white">
                {{ page.publishDate | date: 'd MMMM y' }}
              </time>
              <span *ngIf="page.publishDate && !page.hideDate && page.tags?.length" class="text-white">|</span>
            </span>
            <ul *ngIf="(page.tags?.length || 0) > 0" class="tags mb-0">
              <li *ngFor="let tag of page.tags; let i = index" class="tag">
                <span class="text-white font-primary text-decoration-none">
                  <ng-container>{{ tag.name }}</ng-container>
                  <ng-container *ngIf="page.tags?.length !== i + 1">,</ng-container>
                </span>
              </li>
            </ul>
          </div>

          <p class="text-white lead content-page__header__intro" *ngIf="page.intro" [innerHTML]="page.intro"></p>
        </div>
      </div>
    </div>
  </section>

  <figure class="content-page-header__figure pt-6" *ngIf="page.image?.url">
    <div class="container position-relative">
      <fstr-image
        *ngIf="page.image"
        [imageSizes]="[1800, 1200, 800]"
        [alt]="page.image.alt || ''"
        [image]="page.image"
        [loading]="'eager'"
        [width]="1800"
        [height]="900"
      ></fstr-image>
      <figcaption *ngIf="page.image?.caption">
        <h6 class="mb-0 font-secondary mt-1 text-center" *ngIf="page.image?.caption">{{ page.image?.caption }}</h6>
      </figcaption>
    </div>
  </figure>
</header>
