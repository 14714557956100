import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentPage, Story } from '../../models';
import { IconModule } from '@teamfoster/sdk/icon';
import { CommonModule, Location } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { ImageModule } from '@teamfoster/sdk/image';

@Component({
  selector: 'app-content-page-header',
  templateUrl: './content-page-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconModule, CommonModule, RouterModule, ImageModule],
})
export class ContentPageHeaderComponent {
  private location = inject(Location);
  private router = inject(Router);

  @Input({ required: true }) page!: ContentPage | Story;
  @Input() backRoute?: string[];

  back() {
    if ((this.location.getState() as any).navigationId > 1) {
      this.location.back();
      return;
    }
    this.router.navigate(this.backRoute || ['/']);
  }
}
